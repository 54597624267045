<script>
import Layout from '@layouts/auth'
import { authMethods } from '@state/helpers'

export default {
    components: { Layout },
    data() {
        return {}
    },
    computed: {},
    methods: {
        ...authMethods,
    },
}
</script>

<template>
    <Layout>
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card bg-pattern">
                    <div class="card-body p-4">
                        <div class="text-center w-100 m-auto">
                            <a href="/">
                                <span>
                                    <img
                                        class="img-fluid"
                                        src="@assets/images/logo-dark.png"
                                        alt
                                        height="116"
                                    />
                                </span>
                            </a>
                        </div>

                        <div class="mt-3 text-center">
                            <h3>Your account is registered successfully</h3>
                            <p class="text-muted mt-2">
                                A email has been send to
                                <span class="font-weight-medium"
                                    >youremail@domain.com</span
                                >. Please check for an email from company and
                                click on the included link to reset your
                                password.
                            </p>

                            <router-link
                                v-slot="{ navigate }"
                                to="/login"
                                class="btn btn-block btn-primary waves-effect waves-light mt-3"
                                custom
                            >
                                <a role="link" @click="navigate"
                                    >Back to Home</a
                                >
                            </router-link>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>

<style lang="scss" module></style>
